<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="instructions-modal">
      <q-btn icon="close" @click="closeModal" flat round dense />

      <q-card-section>
        <div class="title">Conseil d'utilisation</div>
      </q-card-section>

      <q-card-section>
        <div class="description">
          <span>
            Vous pouvez cliquer directement sur le document pour ajouter une information
          </span>
          <pre class="">ou</pre>
          <span>
            compléter l’information en utilisant votre clavier dans le champ de texte en bas de la page
          </span>
        </div>
      </q-card-section>

      <q-card-section class="bottom-buttons">
        <q-btn label="Compris" class="btn-blue" @click="closeModal" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'InstructionsModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
