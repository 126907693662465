<template>
  <ejs-pdfviewer
    key="pdfviewer"
    id="container"
    :serviceUrl="serviceUrl"
    ref="pdfViewer"
    :documentLoad="documentLoad"
    :pageClick="pageClick"
    :enableBookmark="false"
    :enableToolbar="true"
    :enableNavigationToolbar="false"
    :enableThumbnail="false"
    :enableAnnotation="true"
    :enableAnnotationToolbar="false"
    :enableStampAnnotations="false"
    :enableDownload="false"
    :zoomMode="zoom_mode"
  />
</template>

<script>
import Vue from 'vue'
import { Auth } from 'aws-amplify'

import {
  PdfViewerPlugin,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  DownloadOption
} from '@syncfusion/ej2-vue-pdfviewer'

Vue.use(PdfViewerPlugin)

export default {
  name: 'PdfViewer',
  props: {
    document: {
      type: Object,
      default: () => {}
    },
    file: {
      type: Object,
      default: () => {}
    },
    drawDataLocation: {
      type: Array,
      default: () => []
    },
    serviceUrl: {
      type: String,
      default: () => `${process.env.VUE_APP_API_URL}/syncfusion/pdfviewer`
    },
    zoom_mode: {
      type: String,
      default: () => 'FitPage'
    },
    goToPage: {
      type: Number,
      default: () => 0
    },
    selectedDataId: {
      type: String,
      default: () => null
    }
  },
  provide: {
    PdfViewer: [
      Toolbar,
      Magnification,
      Navigation,
      LinkAnnotation,
      BookmarkView,
      ThumbnailView,
      Print,
      TextSelection,
      TextSearch,
      Annotation,
      FormFields
    ]
  },
  data() {
    return {
      selectedDrawDataLocation: [],
      localDrawDataLocation: [],
      X_MAX_SYNCFUSION: 0,
      Y_MAX_SYNCFUSION: 0,
      enableAnnotation: false
    }
  },
  async mounted() {
    await this.loadFile()
  },
  created() {
    this.$parent.$on('clear', this.clear)
  },
  methods: {
    async loadFile() {
      const viewer = this.$refs.pdfViewer.ej2Instances

      viewer.toolbar.showToolbarItem(
        new Array(
          'OpenOption',
          'SelectionTool',
          'SearchOption',
          'UndoRedoTool',
          'AnnotationEditTool',
          'CommentTool',
          'SubmitForm',
          'PanTool',
          'PrintOption',
          'DownloadOption'
        ),
        false
      )

      var headers = [
        {
          headerName: 'Authorization',
          headerValue: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      ]
      viewer.ajaxRequestSettings.ajaxHeaders = headers

      if (this.document) {
        viewer.load(this.document.objectName)
        viewer.fileName = this.document.name
        viewer.downloadFileName = this.document.objectName
      }
    },
    pageClick(click) {
      if (this.selectedDataId === null) {
        return
      }
      const { X_MAX_SYNCFUSION, Y_MAX_SYNCFUSION } = this
      const newLocalDrawDataLocation = [...this.localDrawDataLocation]
      const newSelectedDrawDataLocation = []
      for (const coords of this.document.ocrData) {
        const x1 = coords.x1 * X_MAX_SYNCFUSION
        const x2 = coords.x2 * X_MAX_SYNCFUSION
        const y1 = coords.y1 * Y_MAX_SYNCFUSION
        const y2 = coords.y2 * Y_MAX_SYNCFUSION

        if (click.pageNumber - 1 === coords.page) {
          if (
            x1 <= click.x &&
            click.x <= x2 &&
            y1 <= click.y &&
            click.y <= y2
          ) {
            newSelectedDrawDataLocation.push({ ...coords, color: 'red' })
            this.$emit('selected', coords)
            break
          }
        }
      }
      this.localDrawDataLocation = [
        ...newLocalDrawDataLocation,
        ...newSelectedDrawDataLocation
      ]

      this.drawSquare(this.localDrawDataLocation)
    },
    documentLoad(args) {
      const jsonPageData = JSON.parse(args.pageData)
      const pageSizes = jsonPageData['pageSizes']['0'].split(', ')

      this.X_MAX_SYNCFUSION = pageSizes[0]
      this.Y_MAX_SYNCFUSION = pageSizes[1]

      this.drawSquare(this.localDrawDataLocation, true)
    },
    async drawSquare(args, navigation = false) {
      if (document.getElementById('container') == null) {
        return 1
      }
      const pdfViewerInstance = document.getElementById('container')
        .ej2_instances
      if (pdfViewerInstance && pdfViewerInstance[0]) {
        const pdfviewer = pdfViewerInstance[0]
        const headers = [
          {
            headerName: 'Authorization',
            headerValue: `Bearer ${(await this.$Amplify.Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          }
        ]
        pdfviewer.ajaxRequestSettings.ajaxHeaders = headers
        pdfviewer.deleteAnnotations()

        const red = 'rgba(255,0,0,1)'
        const blue = 'rgba(0,0,255,1)'
        const { X_MAX_SYNCFUSION, Y_MAX_SYNCFUSION } = this
        let pages = [...Array(pdfviewer.pageCount)].map(() => new Array())

        args.forEach(item => {
          const x1 = item.x1 * X_MAX_SYNCFUSION
          const y1 = item.y1 * Y_MAX_SYNCFUSION
          const x2 = item.x2 * X_MAX_SYNCFUSION
          const y2 = item.y2 * Y_MAX_SYNCFUSION
          const width = item.width * X_MAX_SYNCFUSION
          const height = item.height * Y_MAX_SYNCFUSION

          const shapeAnnotationObject = {
            ShapeAnnotationType: 'Square',
            Subject: 'Rectangle',
            StrokeColor: item.color === 'red' ? red : blue,
            FillColor: 'rgba(255,255,255,0)',
            Opacity: 1,
            Bounds: {
              X: x1,
              Y: y1,
              Width: width,
              Height: height,
              Location: {
                X: x1,
                Y: y1
              },
              Size: {
                IsEmpty: false,
                Width: width,
                Height: height
              },
              Left: x1,
              Top: y1,
              Right: x2,
              Bottom: y2
            },
            Thickness: 0.5,
            BorderStyle: 'Solid',
            BorderDashArray: 0,
            RotateAngle: 'RotateAngle0',
            IsCloudShape: true,
            CloudIntensity: 0,
            IsLocked: true,
            AnnotType: 'shape'
          }
          if (pages[item.page]) {
            pages[item.page].push(shapeAnnotationObject)
          }
        })

        pages = pages.map(page => {
          return { shapeAnnotation: [...page] }
        })

        const pdfAnnotation = { ...pages }
        // console.log('pdfAnnotation', pdfAnnotation)
        pdfviewer.importAnnotation({
          pdfAnnotation: { ...pdfAnnotation }
        })

        if (this.goToPage !== null && navigation) {
          const page = Number(this.goToPage) + 1
          await pdfviewer.navigation.goToPage(page)
        }
      }
    },
    clear() {
      const newDrawDataLocation = this.drawDataLocation.filter(location => {
        return location.color !== 'red'
      })
      this.selectedDrawDataLocation = []
      this.localDrawDataLocation = newDrawDataLocation
      this.drawSquare(newDrawDataLocation)
    }
  },
  watch: {
    document: function(newDocument, oldDocument) {
      if (
        newDocument &&
        oldDocument &&
        newDocument.objectName !== oldDocument.objectName
      ) {
        this.loadFile()
      }
    },
    drawDataLocation: {
      handler: function() {
        this.localDrawDataLocation = this.drawDataLocation
        this.drawSquare(this.localDrawDataLocation)
      },
      immediate: true
    },
    goToPage: {
      handler: function(newPage) {
        if (newPage == null) {
          return
        }
        const pdfviewer = document.getElementById('container').ej2_instances

        if (pdfviewer && pdfviewer[0]) {
          pdfviewer[0].navigation.goToPage(Number(newPage) + 1)
        }
      }
    }
  }
}
</script>

<style>
/* <!-- Material theme used for this sample --> */
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css';
</style>
