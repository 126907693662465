<template>
  <q-dialog id="dialog" v-model="isShow" persistent>
    <q-card class="delete">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">Modifier l’entité</div>
      </q-card-section>

      <q-card-section>
        <p class="description">
          Si ce nom d’entité est déja utilisé, les informations présentes dans ce document seront rattachées à l’entité existante
        </p>
      </q-card-section>

      <q-card-section class="bottom-buttons">
        <q-btn label="Annuler" class="btn-cancel" @click="discardChanges" />

        <q-btn label="Sauver" class="btn-save" @click="save">
          <q-icon class="save-icon" />
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'LeavePdfBlock',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    getEditEntity() {
      return this.content
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    discardChanges() {
      this.$emit('discardChanges')
    },
    save() {
      this.$emit('saved', true)
    }
  }
}
</script>
