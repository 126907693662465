<template>
  <div id="pdf" class="pdf-block">
    <LeavePdfBlock
      :is-show="isShowLeave"
      @closeModal="closeModalLeave"
      @discardChanges="cancelModalLeave"
      @saved="saved"
    />
    <DeleteModal
      :content.sync="contentDeleteItems"
      :is-show.sync="isShowDelete"
      @closeModal="closeDeleteModal"
      @removed="removed"
    />
    <template v-if="isUseDenominationModal">
      <DenominationModal
        :is-show.sync="isShowDenomination"
        @discardChanges="closeDenominationModal"
        @saved="saved"
        @closeModal="closeDenominationModal"
      />
    </template>
    <div class="relative">
      <div class="top">
        <div class="left">
          <span class="close" v-if="!isInputUse">
            <q-icon name="close" @click="close" />
          </span>
          <div v-if="document && !errorSourceDocumentId">
            <div>Nom de la source</div>
            <p class="flex">
              <span class="pdf-icon"></span>
              {{ documentFileName }}
            </p>
          </div>
        </div>
        <div class="right" v-if="document && !errorSourceDocumentId">
          <template v-if="isInputUse">
            <div class="source-type">
              <div class="title">Type de source</div>
              <q-select
                dense
                rounded
                filled
                @input="val => changeSourceDocument('type', val)"
                v-model="sourceDocumentType"
                :options="optionsSourceType"
              />
            </div>
            <div class="source-date">
              <div class="title">
                Date de la source
              </div>
              <q-input
                filled
                v-model="sourceDocumentDate"
                mask="##/##/####"
                placeholder="dd/mm/yyyy"
                transition-show="scale"
                readonly
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="sourceDocumentDate"
                        mask="DD/MM/YYYY"
                        @input="val => changeSourceDocument('date', val)"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Ok"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </template>
          <template v-else>
            <div>
              <div class="title">Type de source</div>
              <p>{{ document.sourceDocumentType }}</p>
            </div>
            <div>
              <div class="title">Date de la source</div>
              <p>{{ document.sourceDocumentDate }}</p>
            </div>
          </template>

          <div
            class="download-pdf"
            @click="downloadPdf"
          >
            <span class="download-icon"></span>
          </div>

          <div
            class="instructions"
            v-if="isShowInstructions"
            @click="isShowInstructionsModal = true"
          >
            <span>Conseils d'utilisation</span>
            <span class="question-mark-icon"></span>
          </div>
        </div>
      </div>
      <div
        id="wrapper-pdf"
        class="wrapper-pdf"
        v-if="document && !errorSourceDocumentId"
      >
        <PdfViewer
          :document="document"
          :drawDataLocation="drawSquares"
          @selected="pageClick"
          :goToPage="goToPage"
          :selectedDataId="selectedDataId"
        />
      </div>
      <div
        class="error-document wrapper-pdf"
        v-else-if="document === null && selectedDataId === null"
        :drawSquares="drawSquares"
      >
        Veuillez sélectionner une information dans la colonne de gauche pour
        commencer
      </div>
      <div class="error-document wrapper-pdf" v-else-if="errorSourceDocumentId">
        <span
          >Aucun document associé avec cette information, vous pouvez toujours
          modifier cette information en utilisant le champ de texte en bas de
          page</span
        >
      </div>
      <div v-else class="wrapper-pdf"></div>

      <div class="actions">
        <span class="note">
          Informations extraites (cliquez sur le document ou compléter
          l'information manuellement)
        </span>
        <q-form @submit.prevent="onSubmit" class="q-gutter-md">
          <q-input
            :disable="localSelectedDataId === null"
            square
            outlined
            bottom-slots
            v-model="text"
            type="text"
            ref="input"
            class="search"
          >
            <template v-slot:append>
              <q-icon name="clear" class="close" @click="clear" />
            </template>
          </q-input>

          <q-btn
            :disable="localSelectedDataId === null"
            :loading="loading"
            class="btn-save"
            :label="labelSaveButton"
            type="submit"
          >
            <q-icon class="save-icon" />
          </q-btn>
        </q-form>
      </div>
    </div>
    <!-- <LoadingOrEmpty :loading="getLoadingDocument" /> -->
    <InstructionsModal
      :is-show.sync="isShowInstructionsModal"
      @close="closeInstructions"
    />
    <a href="" download ref="downloadLink"></a>
  </div>
</template>

<script>
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
import PdfViewer from '@/components/syncfusion/PdfViewer'
import InstructionsModal from '@/components/modals/Instructions'
import { mapGetters, mapActions, mapState } from 'vuex'
import LeavePdfBlock from '@/components/modals/LeavePdfBlock'
import DeleteModal from '@/components/modals/Delete'
import DenominationModal from '@/components/modals/DenominationModal'
import format from 'date-fns/format'
import axios from 'axios';

export default {
  name: 'PdfBlock',
  components: {
    LoadingOrEmpty,
    PdfViewer,
    InstructionsModal,
    LeavePdfBlock,
    DeleteModal,
    DenominationModal
  },
  props: {
    document: {
      type: Object,
      default: () => {}
    },
    extractedData: {
      type: Array,
      default: () => []
    },
    isShowInstructions: {
      type: Boolean,
      default: true
    },
    selectedDataId: {
      type: String,
      default: ''
    },
    selectedEntityId: {
      type: String,
      default: ''
    },
    isInputUse: {
      type: Boolean,
      default: true
    },
    isPreviewPdf: {
      type: Boolean,
      default: false
    },
    entitiesProject: {
      type: Array,
      default: () => []
    },
    selectedDataLabel: {
      type: String,
      default: () => ''
    },
    isUseDenominationModal: {
      type: Boolean,
      default: false
    },
    labelSaveButton: {
      type: String,
      default: 'Sauver'
    },
    errorSourceDocumentId: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionsSourceType: [
        "Bilan",
        "Bilan actif",
        "Bilan passif",
        "Compte de résultat",
        "Kbis",
        "Liasse fiscale",
        "Statuts",
        "Bail",
        "Carte d'identité",
        "Passeport",
        "Attestation d'hébergement",
        "RIB",
        "Bulletin de paie",
        "Attestation employeur",
        "Avis d'imposition",
        "Autre",
      ],
      isShowLeave: false,
      isShowDelete: false,
      isShowDenomination: false,
      savedDenomination: false,
      localSelectedDataId: '',
      localSelectedEntityId: '',
      sourceDocumentType: '',
      loading: false,
      isTextDirty: false,
      text: '',
      defaultText: '',
      selectedCoords: [],
      newSelectedCoords: [],
      sourceDocumentDate: '',
      goToPage: null,
      isShowInstructionsModal: false,
      contentDeleteItems: {
        title: 'Delete information',
        text: ['Are you sure you want to delete this information ?'],
        labelDelete: 'Delete info'
      }
    }
  },
  computed: {
    ...mapGetters('projects', [
      'getLoadingEntitiesProject',
      'getErrorSendingNewInfo',
      'getSendingEntity',
      'getEntitiesData'
    ]),
    ...mapGetters('documents', ['getLoadingDocument']),
    ...mapState('projects', ['entitiesData']),
    documentFileName() {
      return (
        (this.document &&
          (this.document.documentFileName || this.document.name)) ||
        'Name not found'
      )
    },
    drawSquares() {
      if (this.getLoadingDocument) {
        return
      }
      let arrayDataLocation = []
      const defaultSelectedCoords = []

      if (this.document) {
        const entity =
          (this.document &&
            this.extractedData.find(
              entity => entity.entityId === this.localSelectedEntityId
            )) ||
          false

        this.text = ''
        this.defaultText = ''
        if (entity) {
          entity.entityData.forEach(data => {
            data.sourceDataLocation.forEach(location => {
              if (this.localSelectedDataId === data.dataId) {
                this.text = data.dataValue || ''
              }
              arrayDataLocation.push({
                ...location,
                ...{
                  color:
                    this.localSelectedDataId === data.dataId ? 'red' : 'blue'
                }
              })

              if (this.localSelectedDataId === data.dataId) {
                defaultSelectedCoords.push({ ...location })
                if (this.goToPage === null) {
                  this.goToPage = location.page || 0
                }
                this.defaultText = data.dataValue || ''
              }
            })
          })
        }
      }

      this.selectedCoords = defaultSelectedCoords
      this.newSelectedCoords = defaultSelectedCoords
      return arrayDataLocation
    },
    elApp() {
      return document.getElementById('app')
    }
  },
  created() {
    if (!this.isPreviewPdf) {
      const { projectId } = this.$route.params
      // this.fetchEntitiesProject({ projectId })
    }
  },
  mounted() {
    this.elApp.addEventListener('click', this.clickDocument, true)
  },
  methods: {
    ...mapActions('projects', ['fetchEntitiesProject', 'addNewInfo']),
    ...mapActions('documents', [
      'putAnalyseDocument',
      'deleteAnalyse',
      'setBlockPage',
      'fetchDocument',
      'putDocument'
    ]),
    clickDocument(event) {
      const specifiedElement = document.getElementById('pdf')
      if (specifiedElement === null) {
        this.setBlockPage(false)
      } else {
        if (this.isInputUse && this.isTextDirty) {
          const wrapperPdf = document.getElementById('wrapper-pdf')
          const dialogElement = document.getElementById('dialog')

          const isClickInside =
            specifiedElement.contains(event.target) ||
            (dialogElement !== null && dialogElement.contains(event.target)) ||
            (wrapperPdf !== null && wrapperPdf.contains(event.target))

          if (!isClickInside) {
            if (this.text !== this.defaultText || this.isTextDirty) {
              event.stopPropagation()
              this.$set(this, 'isShowLeave', true)
            } else {
              event.stopPropagation()
              this.$emit('close')
            }
          }
          return
        } else if (!this.isInputUse) {
          const dialogElement = document.getElementById('dialog')
          const wrapperPdf = document.getElementById('wrapper-pdf')
          const isClickInside =
            specifiedElement.contains(event.target) ||
            (dialogElement !== null && dialogElement.contains(event.target)) ||
            (wrapperPdf !== null && wrapperPdf.contains(event.target))
          if (!isClickInside || event.target.innerHTML === 'close') {
            event.stopPropagation()
            if (this.text !== this.defaultText || this.isTextDirty) {
              this.$set(this, 'isShowLeave', true)
            } else {
              this.$emit('close')
            }
          } else if (event.target.innerHTML === 'clear') {
            event.stopPropagation()
            this.clear()
          }
        }
      }
    },
    pageClick(coords) {
      if (this.selectedDataId === null) {
        return
      }
      const newSelectedCoords = [...this.newSelectedCoords]
      newSelectedCoords.push(coords)
      this.newSelectedCoords = newSelectedCoords
      this.text = this.text + ' ' + coords.text
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    async onSubmit(e) {
      if (this.text.trim() === '') {
        this.isShowDelete = true
        return
      }

      this.loading = true
      let entity = this.entitiesProject.length
        ? this.entitiesProject.find(
            entity => entity.entityId === this.localSelectedEntityId
          )
        : this.document &&
          this.document.extractedData &&
          this.document.extractedData.find(
            entity => entity.entityId === this.localSelectedEntityId
          )

      if (entity === undefined) {
        entity =
          this.extractedData.find(
            entity => entity.entityId === this.localSelectedEntityId
          ) ||
          this.extractedData.map(entity => {
            return entity.entityData.find(
              data => data.dataId === this.localSelectedDataId
            )
          })
      }
      const entityData =
        (entity.entityData &&
          entity.entityData.find(entity => {
            return entity.dataId === this.localSelectedDataId
          })) ||
        entity

      const specialLabels = ['Dénomination', 'Denomination']
      if (
        !this.savedDenomination &&
        specialLabels.includes(entityData.dataLabel) &&
        this.isUseDenominationModal
      ) {
        this.isShowDenomination = true
        return
      }
      const defaultDate = new Date()

      const _data = {
        dataId: entityData.dataId || this.localSelectedDataId,
        dataCode: entityData.dataCode,
        entityId: entityData.entityId || entity.entityId,
        dataValue: this.text || ' ',
        sourceDataLocation: this.newSelectedCoords.map(coords => {
          delete coords.text
          coords.page = Number(coords.page)
          return coords
        })
      }

      try {
        if (_data.dataId.includes('CUSTOM')) {
          if (_data.sourceDataLocation && _data.sourceDataLocation.length) {
            _data.sourceDocumentId = this.document.documentId
            _data.sourceDocumentType = this.sourceDocumentType || 'MANUAL'
          } else {
            delete _data.sourceDataLocation
          }
          delete _data.dataId

          _data.dataLabel = entityData.dataLabel || this.selectedDataLabel
          _data.dataCode = entityData.dataCode
          _data.dataDefault = true
          _data.sourceDocumentType = this.sourceDocumentType || 'MANUAL'
          _data.sourceDocumentDate =
            this.sourceDocumentDate || format(defaultDate, 'dd/MM/yyyy')

          await this.addNewInfo(_data)
        } else {
          if (
            _data.dataCode === 'DENOMINATION' &&
            this.isUseDenominationModal
          ) {
            _data.forceJoin = true
          }

          await this.putAnalyseDocument(_data)
        }

        if (this.document && this.document.documentId) {
          this.fetchDocument({ documentId: this.document.documentId })
        }

        this.setBlockPage(false)
        this.isTextDirty = false
        const { colorEmpty, iconEmpty } = this.$q.config.alert
        this.newSelectedCoords = []
        this.$emit('saved', _data)
        this.showNotify('Information modifiée', colorEmpty, iconEmpty)
      } catch (err) {
        this.showNotify('Error while updating data')
      }
      this.savedDenomination = false
      this.isShowDenomination = false
      this.loading = false
      this.$emit('close', _data)
    },
    saved(savedDenomination = false) {
      this.savedDenomination = savedDenomination
      this.onSubmit()
    },
    clear() {
      this.text = ''
      this.newSelectedCoords = []
      this.$refs.input.blur()
      this.$emit('clear')
    },
    close() {
      if (this.isTextDirty) {
        this.isShowLeave = true
      } else {
        this.$emit('close')
      }
    },
    closeDeleteModal() {
      this.isShowDelete = false
    },
    cancelModalLeave() {
      this.isShowLeave = false
      this.selectedCoords = []
      this.newSelectedCoords = []
      this.text = this.defaultText
      this.setBlockPage(false)
    },
    closeModalLeave() {
      this.isShowLeave = false
    },
    closeDenominationModal() {
      this.loading = false
    },
    async removed() {
      this.text = ''
      this.$refs.input.blur()
      const entity =
        (this.document &&
          this.document.extractedData &&
          this.document.extractedData.find(
            entity => entity.entityId === this.localSelectedEntityId
          )) ||
        this.document.extractedData[0]
      await this.deleteAnalyse({
        dataId: this.localSelectedDataId,
        entityId: entity.entityId
      })
      this.$emit('clear')
      this.$emit('close')
      this.isShowDelete = false
      if (!this.isPreviewPdf) {
        this.isTextDirty = false
        this.setBlockPage(false)
        this.fetchDocument({ documentId: this.document.documentId })
      }
    },
    showNotify(message, color, icon) {
      const { timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    closeInstructions() {
      this.isShowInstructionsModal = false
    },
    downloadPdf() {
      var document_name = this.document.documentFileName.split('.pdf')[0] + '.pdf'

      axios.get(this.document.s3Url, {
        headers: {
          "Content-Type": "application/octet-stream"
        },
        responseType: "blob"
      })
        .then(response => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = document_name;
          a.click();
        })
        .catch(err => {
          console.log("error", err);
        });
    },
    closeDenominationModal() {
      this.text = this.defaultText
      this.isShowDenomination = false
      this.loading = false
      this.selectedCoords = []
      this.newSelectedCoords = []
    },
    async changeSourceDocument(source, value) {
      const sourceDocumentType =
        source === 'type' ? value : this.sourceDocumentType
      const sourceDocumentDate =
        source === 'date' ? value : this.sourceDocumentDate
      this.sourceDocumentType = sourceDocumentType
      this.sourceDocumentDate = sourceDocumentDate
      const _data = {
        sourceDocumentType,
        sourceDocumentDate
      }
      try {
        const documentId = this.document.documentId
        await this.putDocument({ documentId, data: _data })
        if (source === 'date') {
          this.$router.go()
        }
        const { colorEmpty, iconEmpty } = this.$q.config.alert
        this.showNotify('Data changed', colorEmpty, iconEmpty)
      } catch (err) {
        this.showNotify('Error while updating data')
      }
    }
  },
  watch: {
    document: {
      immediate: true,
      handler: function(newDocument) {
        this.sourceDocumentDate = newDocument?.sourceDocumentDate || ''
        this.sourceDocumentType = newDocument?.sourceDocumentType || ''
        if (this.errorSourceDocumentId) {
          const entity = this.getEntitiesData[this.localSelectedEntityId] || []
          const data = entity.find(
            data => this.localSelectedDataId === data.dataId
          )
          this.text = data?.dataValue || ''
          this.defaultText = data?.dataValue || ''
        }
      }
    },
    errorSourceDocumentId: {
      immediate: true,
      handler: function(newStatus) {
        if (newStatus) {
          const entities = this.entitiesData
          console.log(entities)
          const entity = entities[this.localSelectedEntityId] || []
          const data = entity.find(
            data => this.localSelectedDataId === data.dataId
          )
          this.text = data?.dataValue || ''
          this.defaultText = data?.dataValue || ''
        }
      }
    },
    text: function(newText) {
      if (newText !== this.defaultText) {
        this.isTextDirty = true
        this.setBlockPage(true)
      } else {
        this.isTextDirty = false
        this.setBlockPage(false)
      }
    },
    selectedDataId: {
      immediate: true,
      handler: function(newSelectedDataId, oldSelectedDataId) {
        if (newSelectedDataId !== oldSelectedDataId) {
          this.goToPage = null
          this.$set(this, 'localSelectedDataId', newSelectedDataId)

          if (newSelectedDataId && newSelectedDataId.includes('CUSTOM')) {
            this.text = ''
            this.defaultText = ''
          }
        }
      }
    },
    selectedEntityId: {
      immediate: true,
      handler: function(newSelectedEntityId) {
        this.$set(this, 'localSelectedEntityId', newSelectedEntityId)
      }
    }
  },
  beforeDestroy() {
    this.goToPage = null
    this.elApp.removeEventListener('click', this.clickDocument, true)
    this.setBlockPage(false)
  },
  destroyed() {
    this.elApp.removeEventListener('click', this.clickDocument, true)
  }
}
</script>
